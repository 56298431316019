<template>
  <div>
    <div v-if="detail" class="p-10">
      <div>{{ $t('nodeName') + $t('colon') + detail.nodeName }}</div>
      <div>
        {{
          $t('inventoryOrderCode') +
          $t('colon') +
          (detail.inventoryOrderCode || '')
        }}
      </div>
      <div>{{ $t('date') + $t('colon') + detail.inventoryTime }}</div>
    </div>
    <van-cell-group>
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="'SKU：' + item.productCode"
      >
        <template #label>
          <div class="f-cb">
            <div>
              {{ $t('sapOrderNumber') + $t('colon') + item.sapOrderNumber }}
            </div>
            <div>
              {{ $t('tradeNum') + $t('colon') + item.tradeNum }}
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: null,
      list: [],
    }
  },
  created() {
    this.detail = this.$route.params
    this.listAppInventoryOrderDetail()
  },
  methods: {
    async listAppInventoryOrderDetail() {
      const { inventoryOrderCode } = this.detail
      const { data } = await this.$api.listAppInventoryOrderDetail({
        inventoryOrderCode,
      })
      console.log('listAppInventoryOrderDetail===>', data)
      this.list = data
    },
  },
}
</script>